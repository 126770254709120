import React, { useContext, useState } from "react";
import { STATUS_OPTIONS } from "../../../../../../components/status-popup-component";

const AddTransferOrderFeatureStateContext = React.createContext({
  formData: {},
  setFormData: (newFormData) => {},
  submitStatus: "",
  setSubmitStatus: () => {}
});

export const useAddTransferOrderFeatureStateContext = () => {
  return useContext(AddTransferOrderFeatureStateContext);
};

export const AddTransferOrderFeatureStateContextProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [submitStatus, setSubmitStatus] = useState(STATUS_OPTIONS.NONE);

  return (
    <AddTransferOrderFeatureStateContext.Provider
      value={{
        formData,
        setFormData,
        submitStatus,
        setSubmitStatus
      }}
    >
      {children}
    </AddTransferOrderFeatureStateContext.Provider>
  );
};

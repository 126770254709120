import React, { useState, useEffect, useMemo, Fragment } from "react";
import { getStackedXemelgoLogo, uploadImageToS3, imageValidityCheck, TIME_IN_MS } from "common/Utilities";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { MoreHoriz } from "@material-ui/icons";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "mdbreact";
import { Alert } from "react-bootstrap";
import DetailScreen from "../components/DetailScreen";
import { useXemelgoClient } from "../services/xemelgo-service";
import Style from "./ItemTypeDetailScreen.module.css";
import { useAppConfigProvider } from "../services/soft-cache-service";
import { LocalCacheService } from "../services/local-cache-service";
import { ReactComponent as ItemTypesMangementIcon } from "../assets/icons/item-types-management.svg";
import xemelgoStyle from "../styles/variable";
import useMixpanelContext from "../context/mixpanel-context";
import {
  ITEM_TYPE_MANAGEMENT_PAGE,
  ITEM_TYPE_MANAGEMENT_PAGE_STEPS
} from "../constants/mixpanel-constant/itemTypeManagementPage";
import LoadingCircle from "../components/loading/LoadingCircle";
import { pluralizeWord } from "../common/Utilities";
import GeneralizedModal from "../components/modal/GeneralizedModal";

import CONFIGURATION from "../configuration.json";

const APP_ID = "itemTypesManagement";
const FEATURE_ID = "itemTypeDetail";

export default ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const configProvider = useAppConfigProvider(APP_ID);
  const ItemTypeDetailScreenConfig = configProvider.getValue(FEATURE_ID, "object") || {
    imageConfig: {},
    attributeMap: {},
    tabs: []
  };
  const userProfile = LocalCacheService.loadUserProfile();
  const tenantId = userProfile.getTenantId();
  const defaultXemelgoLogo = getStackedXemelgoLogo("dark");

  const [itemTypeDetailInfo, setItemTypeDetailInfo] = useState({});

  const [itemTypeId, setItemTypeId] = useState(null);
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [image, setImage] = useState(null);
  const [imageFileToUpload, setImageFileToUpload] = useState(null);
  const [detailInfo, setDetailInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);

  const { sendMixPanelEvent } = useMixpanelContext();

  useEffect(() => {
    const { tabs: tabsConfig = [] } = ItemTypeDetailScreenConfig;
    const { search } = location;
    const { id: newItemTypeId } = queryString.parse(search);

    // update url to the detail tab if default is provided
    const defaultTab = tabsConfig.find((each) => {
      return each.default;
    });
    if (defaultTab) {
      history.replace(`${defaultTab.path}?id=${newItemTypeId}`);
    }

    const image = getStackedXemelgoLogo("dark");
    setImage(image);
    setItemTypeId(newItemTypeId);
    sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.DETAIL_PAGE_ENTRY);
  }, []);

  const fetchItemType = async () => {
    const attributeList = ["id", ...Object.keys(ItemTypeDetailScreenConfig?.attributeMap)];
    if (ItemTypeDetailScreenConfig?.imageConfig?.id) {
      attributeList.push(ItemTypeDetailScreenConfig?.imageConfig?.id);
    }
    const { itemTypes: itemTypeList } = await itemTypeClient.getItemTypesByIds(itemTypeId, attributeList);

    setItemTypeDetailInfo(itemTypeList[0]);
  };

  const onLoad = async () => {
    if (itemTypeId) {
      setIsLoading(true);
      await fetchItemType();
    }
  };

  useEffect(() => {
    onLoad();
  }, [itemTypeId]);

  useEffect(() => {
    const newDetailInfo = Object.keys(ItemTypeDetailScreenConfig?.attributeMap).reduce((accumulator, eachKey) => {
      accumulator[eachKey] = {
        ...ItemTypeDetailScreenConfig?.attributeMap[eachKey],
        value: itemTypeDetailInfo[eachKey]?.toString() || ""
      };
      return accumulator;
    }, {});

    if (itemTypeDetailInfo[ItemTypeDetailScreenConfig?.imageConfig?.id]) {
      const image_path = itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig.id];
      imageValidityCheck(image_path, (isValid) => {
        setImage(
          isValid
            ? `${itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig?.id]}?t=${new Date().getTime()}`
            : defaultXemelgoLogo
        );
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    setDetailInfo(newDetailInfo);
  }, [itemTypeDetailInfo]);

  const tabs = useMemo(() => {
    const { tabs: tabsConfig = [] } = ItemTypeDetailScreenConfig;
    return tabsConfig
      .map(({ path, title, index }) => {
        return { pathname: path, title, index };
      })
      .sort((a, b) => {
        return a.index - b.index;
      });
  }, [ItemTypeDetailScreenConfig?.tabs]);

  const onEditSave = async (newDetailInfo) => {
    setDetailInfo({ ...newDetailInfo });
    const hasUpdate = Object.keys(newDetailInfo).reduce((accumulator, eachKey) => {
      return accumulator || newDetailInfo[eachKey].value !== detailInfo[eachKey].value;
    }, false);

    const hasImagePath =
      ItemTypeDetailScreenConfig?.imageConfig?.id && itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig.id];

    if (imageFileToUpload && imageFileToUpload.length > 0) {
      setIsLoading(true);

      const imageFile = imageFileToUpload[0].file;
      const uploadParams = {
        Bucket: CONFIGURATION.s3_customer_images_configuration.bucket_name,
        Key: `${CONFIGURATION.production ? "prod" : "dev"}/${tenantId}/itemType/${itemTypeId}.png`,
        Body: imageFile
      };

      try {
        await uploadImageToS3(uploadParams);
        /* the setImage has ?t=${new Date().getTime() added to it to force the <img/> component to refresh
         * and load the new image that was uploaded, since the imagePath of the item type does not change on
         * uploading a new image
         */
        setImage(`${itemTypeDetailInfo[ItemTypeDetailScreenConfig.imageConfig.id]}?t=${new Date().getTime()}`);
        setImageFileToUpload(null);
        if (!hasImagePath) {
          await itemTypeClient.updateItemType(itemTypeId, {
            image_path: `https://${CONFIGURATION.s3_customer_images_configuration.bucket_name}.s3-${
              CONFIGURATION.s3_customer_images_configuration.region
            }.amazonaws.com/${CONFIGURATION.production ? "prod" : "dev"}/${tenantId}/itemType/${itemTypeId}.png`
          });
        }
        await fetchItemType();
      } catch (e) {}
    }

    if (hasUpdate) {
      setIsLoading(true);
      await itemTypeClient.updateItemType(
        itemTypeId,
        Object.keys(newDetailInfo).reduce((accumulator, eachKey) => {
          const millisecondsConversionUnit =
            ItemTypeDetailScreenConfig?.attributeMap[eachKey].millisecondsConversionUnit;
          if (newDetailInfo[eachKey].editable) {
            switch (ItemTypeDetailScreenConfig?.attributeMap[eachKey].type) {
              case "number":
                accumulator[eachKey] = newDetailInfo[eachKey].value.replace(/\D/g, "") * 1;
                if (
                  millisecondsConversionUnit &&
                  TIME_IN_MS[millisecondsConversionUnit] &&
                  accumulator[eachKey] !== itemTypeDetailInfo[eachKey]
                ) {
                  accumulator[eachKey] *= TIME_IN_MS[millisecondsConversionUnit];
                }
                break;
              default:
                accumulator[eachKey] = newDetailInfo[eachKey].value;
                break;
            }
          }
          return accumulator;
        }, {})
      );
      await fetchItemType();
      setIsLoading(false);
    }
  };

  const dropdownMenu = () => {
    return (
      <Dropdown>
        <DropdownToggle
          nav
          className={Style.toggle}
        >
          <MoreHoriz />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            className={Style.red_text}
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteErrorMessage("");
            }}
          >
            Delete Item Type
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderDeleteModal = () => {
    const itemTypeIdentifier = itemTypeDetailInfo.identifier;
    const title = `Delete ${itemTypeIdentifier}?`;
    const modalBodyComponent = (
      <>
        {deleteErrorMessage && <Alert variant="danger">{deleteErrorMessage}</Alert>}
        {deleteModalLoading ? (
          <div className={Style.loading_spinner_container}>
            <LoadingCircle />
          </div>
        ) : (
          <div className={Style.delete_prompt_container}>
            <p>
              Do you want to permanently delete
              <span className={Style.bold_text}> {itemTypeIdentifier}</span>? Please ensure that all associated items
              have already been deleted.
            </p>
          </div>
        )}
      </>
    );
    return (
      <GeneralizedModal
        showModal={showDeleteModal}
        title={title}
        modalBodyComponent={modalBodyComponent}
        modalFooterButtons={[
          {
            id: "cancel",
            title: "Cancel",
            className: "cancel-button",
            onClick: () => {
              setShowDeleteModal(false);
            }
          },
          {
            id: "delete",
            title: "Delete",
            className: deleteErrorMessage ? Style.confirm_delete_button_hidden : Style.confirm_delete_button,
            onClick: () => {
              handleDelete();
            }
          }
        ]}
      />
    );
  };

  const handleDelete = async () => {
    try {
      setDeleteModalLoading(true);
      setDeleteErrorMessage("");
      const currentItemTypeInfo = await itemTypeClient.getItemTypeAndItems(itemTypeId);
      const { items } = currentItemTypeInfo;
      if (items?.length) {
        const itemNoun = "item";
        const alertMessage = `This item type is currently tracking ${items.length} ${
          items.length > 1 ? pluralizeWord(itemNoun) : itemNoun
        } and cannot be deleted. Please reach out to Xemelgo support if you need help deleting those items.`;
        setDeleteErrorMessage(alertMessage);
      } else {
        await itemTypeClient.removeItemType(itemTypeId);
        setShowDeleteModal(false);
        history.goBack();
      }
    } catch (error) {
      setDeleteErrorMessage(error.message);
    }
    setDeleteModalLoading(false);
  };

  return (
    <>
      <DetailScreen
        isLoading={isLoading}
        TitleIconComponent={() => {
          return (
            <ItemTypesMangementIcon
              width={28}
              height={28}
              style={{ color: xemelgoStyle.theme.XEMELGO_BLUE }}
            />
          );
        }}
        title="Item Type Details"
        tabs={tabs}
        data={detailInfo}
        images={[image]}
        setImageFileToUploadFn={setImageFileToUpload}
        onEditSave={onEditSave}
        DropdownComponent={dropdownMenu}
      >
        {!!ItemTypeDetailScreenConfig.tabs?.length && children}
      </DetailScreen>
      {renderDeleteModal()}
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Style from "./SelectedFiltersControl.module.css";

export const SelectedFiltersControl = ({ filters, onRemoveFilter, onClearFilters }) => {
  return (
    <div className={Style.flex_row}>
      <p className={Style.black_text}>Filters:</p>
      <div className={Style.selected_filters_container}>
        {filters.map((filter) => {
          return (
            <div
              key={`${filter.key}-${filter.value}`}
              className={Style.filter}
            >
              {filter.value}
              <CloseIcon
                onClick={() => {
                  onRemoveFilter(filter);
                }}
                className={Style.close_icon}
              />
            </div>
          );
        })}
        <button
          className={Style.clear_filters_text}
          onClick={onClearFilters}
          type="button"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

SelectedFiltersControl.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired
};

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useStore } from "react-hookstore";
import PasswordInputFormWithValidation from "../../../features/password-form-with-input-validation/PasswordInputFormWithValidation";
import { loggingUserStore } from "../../../state-managements/stores/login-store";

const NewPasswordForm = ({ logo, onCancel, onSubmit }) => {
  const [loggingUser] = useStore(loggingUserStore);

  const onSubmit_ = useCallback(
    async (newPassword) => {
      await onSubmit(null, newPassword);
    },
    [onSubmit]
  );

  return (
    <div className="content-body login-page-body">
      <div
        className="container login-form"
        style={{ textAlign: "initial" }}
      >
        <div className="login-form-background">
          <img
            src={logo}
            className="brand-logo"
            alt="brand-logo"
          />

          <div className="form-group">
            <p
              style={{
                textAlign: "center",
                color: "white"
              }}
            >
              {`Welcome! Please enter a new password for your email - ${loggingUser}`}
            </p>
            <PasswordInputFormWithValidation
              onSubmit={onSubmit_}
              submitButtonText="Submit"
              cancelButtonText="Cancel"
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

NewPasswordForm.propTypes = {
  logo: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export { NewPasswordForm };

import { camelCase } from "lodash";

  /**
   * @param {string} text 
   * @param {string[]} textFormatOptions 
   * @returns {string}
   */
  export const formatText = (text, textFormatOptions) => {
    if (!textFormatOptions || typeof text !== "string") {
      return text;
    }
    let formattedText = text;
    textFormatOptions.forEach((option) => {
      switch (option) {
        case "uppercase":
          formattedText = formattedText.toUpperCase();
          break;
        case "lowercase":
          formattedText = formattedText.toLowerCase();
          break;
        case "capitalize":
          formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
          break;
        case "trim":
          formattedText = formattedText.trim();
          break;
        case "camelcase":
          formattedText = camelCase(formattedText);
          break;
        default:
          break;
      }
    });
    return formattedText;
  };

import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { getPartnerDisplayValue } from "../../ListPartnersUtil";
import Loading from "../../../../components/loading/LoadingWord";
import { ModalForm } from "../../../../components/modal-form";
import { DeletePartnerFormFooter } from "./delete-partner-form-footer/DeletePartnerFormFooter";

export const DeletePartnerForm = ({ show, partner, onSubmit, onCancel, displayProperty }) => {
  const [loading, setLoading] = useState(false);
  const onSubmitCallback = useCallback(() => {
    setLoading(true);
    onSubmit(partner.id);
  }, [onSubmit, partner]);

  const displayValue = getPartnerDisplayValue(partner, displayProperty);
  return (
    <>
      {loading && <Loading />}
      <ModalForm
        show={show}
        title={`Delete ${displayValue}?`}
        body={`You're about to delete ${displayValue}, are you sure you want to do this?`}
        footer={
          <DeletePartnerFormFooter
            onSubmit={onSubmitCallback}
            onCancel={onCancel}
          />
        }
      />
    </>
  );
};

DeletePartnerForm.propTypes = {
  show: PropTypes.bool.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

import React, { useState, useEffect, useMemo } from "react";
import { cloneDeep } from "lodash";
import Style from "./PopupFilter.module.css";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { ReactComponent as ChevDownIcon } from "../../../../assets/icons/chev-down.svg";
import Filters, { useFiltersConfigBuilder, FILTER_TYPE } from "../../../../components/filters";
import useAssetMapConfigContext from "../../contexts/asset-map-config-context";
import useAssetMapStateContext from "../../contexts/asset-map-state-context";

export const PopupFilter = () => {
  const { popupFilterControl } = useAssetMapConfigContext();
  const { filterValue, setFilterValue, setSelectedAssetTypeSet } = useAssetMapStateContext();
  const { filterConfiguration, initialFilterValue, isLoading } = useFiltersConfigBuilder(popupFilterControl);

  const [showMenu, setShowMenu] = useState(false);
  const [currentFiltersValue, setCurrentFiltersValue] = useState(initialFilterValue);

  const numOfFilterSelected = useMemo(() => {
    return popupFilterControl.reduce((accumulator, eachFilterControl) => {
      const { type, id } = eachFilterControl || {};

      const eachFilterValue = currentFiltersValue?.[id];

      switch (type) {
        case FILTER_TYPE.checkboxes:
          return (
            accumulator +
            ((eachFilterValue || []).filter(({ value }) => {
              return value;
            }).length || 0)
          );
        case FILTER_TYPE.dateRange:
          return accumulator + (eachFilterValue?.startTime || eachFilterValue?.endTime ? 1 : 0);
        case FILTER_TYPE.searchDropdown:
          return accumulator + (Object.keys(eachFilterValue?.value || {}).length ? 1 : 0);
        case FILTER_TYPE.input:
          return accumulator + (eachFilterValue?.value?.length || 0);
        default:
      }

      return accumulator;
    }, 0);
  }, [currentFiltersValue, popupFilterControl]);

  useEffect(() => {
    setCurrentFiltersValue(cloneDeep(filterValue || initialFilterValue));
  }, [initialFilterValue, filterValue, showMenu]);

  if (!popupFilterControl?.length) {
    return null;
  }

  return (
    <div className={Style.container}>
      <button
        type="button"
        className={Style.filter_button}
        onClick={() => {
          setShowMenu((currentValue) => {
            return !currentValue;
          });
        }}
      >
        <FilterIcon />
        <p className={Style.filter_button_text}>
          {`Filters ${numOfFilterSelected > 0 ? `(${numOfFilterSelected})` : ""}`}
        </p>
        <ChevDownIcon />
      </button>
      {showMenu && (
        <div className={Style.filter_menu}>
          <div className={Style.menu_content}>
            <div className={Style.menu_header_container}>
              <div className={Style.menu_title_container}>
                <p className={Style.menu_title_text}>Filter By</p>
                <p className={Style.menu_title_subtext}>
                  {`(${numOfFilterSelected || 0} filter${numOfFilterSelected === 1 ? "" : "s"} selected)`}
                </p>
              </div>
              <button
                type="button"
                className={Style.clear_all_text_button}
                onClick={() => {
                  setCurrentFiltersValue(cloneDeep(initialFilterValue));
                }}
              >
                Clear All
              </button>
            </div>
            <div>
              <Filters
                isLoading={isLoading}
                filterConfiguration={filterConfiguration}
                initialFilterValues={{ ...currentFiltersValue }}
                onFilterChange={({ allValues }) => {
                  setCurrentFiltersValue(allValues);
                }}
              />
            </div>
          </div>
          <div className={Style.menu_buttons_container}>
            <button
              type="button"
              className={`${Style.button_base} ${Style.cancel_button}`}
              onClick={() => {
                setShowMenu(false);
                setCurrentFiltersValue(cloneDeep(initialFilterValue));
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`${Style.button_base} ${Style.apply_button}`}
              onClick={() => {
                setShowMenu(false);
                setFilterValue(currentFiltersValue);

                const selectedAssetTypeSet = new Set();
                currentFiltersValue.itemType.forEach((filter) => {
                  const { id, value } = filter;
                  if (id && value) {
                    selectedAssetTypeSet.add(id);
                  }
                });
                setSelectedAssetTypeSet(selectedAssetTypeSet);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

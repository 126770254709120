import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { PRINT_TYPES } from "../../../../data/constants";
import useOnboardingForm from "../../../../hooks/use-onboarding-form";

const FEATURE_ID = "addAsset";
const APP_ID = "asset";
const DEFAULT_ONBOARDING_TAB = "defaultOnboardingTab";
const FORM_SECTIONS = "formSections";
const CUSTOM_TEMPLATE = "customTemplate";
const IS_PRINT_ENABLED = "isPrintEnabled";
const IS_BULK_CREATE_ENABLED = "isBulkCreateEnabled";
const PRINT_TYPE = "printType";
const IS_PRINT_LATER_ENABLED = "isPrintLaterEnabled";
const GENERATE_IDENTIFIER_OPTIONS = "generateIdentifierOptions";
const SHOW_LAST_ENTRIES_CHECKBOX = "showSaveEntriesCheckbox";

const initialState = {
  formSections: [],
  formFields: {},
  bartenderConfig: {},
  defaultOnboardingTab: "single",
  customTemplate: "",
  isPrintEnabled: false,
  printType: PRINT_TYPES.ZPL,
  isBulkCreateEnabled: false,
  isPrintLaterEnabled: false,
  generateIdentifierOptions: {
    separator: "-"
  },
  zplConfig: {},
  categorySearchEnabled: false,
  subcategorySearchEnabled: false,
  showSaveEntriesCheckbox: false
};

const AddAssetFeatureConfigContext = React.createContext();

export const useAddAssetFeatureConfigContext = () => {
  return useContext(AddAssetFeatureConfigContext);
};

export const AddAssetFeatureConfigContextProvider = ({ children }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const { fetchFormOptions } = useOnboardingForm();
  const [isLoading, setIsLoading] = useState(true);
  const [formSections, setFormSections] = useState(initialState.formSections);
  const [formFields, setFormFields] = useState(initialState.formFields);

  const [defaultOnboardingTab, setDefaultOnboardingTab] = useState(initialState.defaultOnboardingTab);
  const [customTemplate, setCustomTemplate] = useState(initialState.customTemplate);
  const [isPrintEnabled, setIsPrintEnabled] = useState(initialState.isPrintEnabled);
  const [bartenderConfig, setBartenderConfig] = useState(initialState.bartenderConfig);
  const [printType, setPrintType] = useState(initialState.printType);
  const [zplConfig, setZplConfig] = useState(initialState.zplConfig);
  const [isBulkCreateEnabled, setIsBulkCreateEnabled] = useState(initialState.isBulkCreateEnabled);
  const [isPrintLaterEnabled, setIsPrintLaterEnabled] = useState(initialState.isPrintLaterEnabled);
  const [generateIdentifierOptions, setGenerateIdentifierOptions] = useState(initialState.generateIdentifierOptions);
  const [categorySearchEnabled, setCategorySearchEnabled] = useState(initialState.categorySearchEnabled);
  const [subcategorySearchEnabled, setSubcategorySearchEnabled] = useState(initialState.subcategorySearchEnabled);
  const [showSaveEntriesCheckbox, setShowSaveEntriesCheckbox] = useState(initialState.showSaveEntriesCheckbox)

  const getFormSections = async (newFormSections) => {
    const { fetchedFormFields, fetchedFormSections } = await fetchFormOptions(newFormSections, "Asset");

    setFormFields(fetchedFormFields);
    setFormSections(fetchedFormSections);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const addAssetConfigurationV1 = configProvider.getValue(FEATURE_ID, "object");
    const addAssetConfiguration = addAssetConfigurationV1.v2 || {};
    const { assetBartenderConfig = {} } = configProvider.getModelMap();

    const newFormSections = addAssetConfiguration[FORM_SECTIONS] || initialState.formSections;
    const newIsBulkCreateEnabled = addAssetConfiguration[IS_BULK_CREATE_ENABLED] || initialState.isBulkCreateEnabled;
    const newCustomTemplate = addAssetConfiguration[CUSTOM_TEMPLATE] || initialState.customTemplate;
    const newDefaultOnboardingTab = addAssetConfiguration[DEFAULT_ONBOARDING_TAB] || initialState.defaultOnboardingTab;
    const newIsPrintEnabled = addAssetConfiguration[IS_PRINT_ENABLED] || initialState.isPrintEnabled;
    const newPrintType = addAssetConfiguration[PRINT_TYPE] || initialState.printType;
    const newAssetBartenderConfig = assetBartenderConfig.def || initialState.bartenderConfig;
    const newPrintLaterEnabled = addAssetConfiguration[IS_PRINT_LATER_ENABLED] || initialState.isPrintLaterEnabled;
    const newGenerateIdentifierOptions =
      addAssetConfiguration[GENERATE_IDENTIFIER_OPTIONS] || initialState.generateIdentifierOptions;
    const newCategorySearchEnabled = addAssetConfiguration.categorySearchEnabled || initialState.categorySearchEnabled;
    const newSubcategorySearchEnabled =
      addAssetConfiguration.subcategorySearchEnabled || initialState.subcategorySearchEnabled;
    const newShowSaveEntriesCheckbox =
      addAssetConfiguration[SHOW_LAST_ENTRIES_CHECKBOX] || initialState.showSaveEntriesCheckbox;

    setBartenderConfig(newAssetBartenderConfig);
    setIsBulkCreateEnabled(newIsBulkCreateEnabled);
    setCustomTemplate(newCustomTemplate);
    setPrintType(newPrintType);
    setZplConfig({ customTemplate: newCustomTemplate });
    setDefaultOnboardingTab(newDefaultOnboardingTab);
    setIsPrintEnabled(newIsPrintEnabled);
    getFormSections(newFormSections);
    setIsPrintLaterEnabled(newPrintLaterEnabled);
    setGenerateIdentifierOptions(newGenerateIdentifierOptions);
    setCategorySearchEnabled(newSubcategorySearchEnabled || newCategorySearchEnabled);
    setSubcategorySearchEnabled(newSubcategorySearchEnabled);
    setShowSaveEntriesCheckbox(newShowSaveEntriesCheckbox);
    setIsLoading(false);
  }, [configProvider]);

  return (
    <AddAssetFeatureConfigContext.Provider
      value={{
        isLoading,
        formSections,
        formFields,
        bartenderConfig,
        defaultOnboardingTab,
        customTemplate,
        isPrintEnabled,
        printType,
        zplConfig,
        isBulkCreateEnabled,
        isPrintLaterEnabled,
        generateIdentifierOptions,
        categorySearchEnabled,
        subcategorySearchEnabled,
        showSaveEntriesCheckbox
      }}
    >
      {children}
    </AddAssetFeatureConfigContext.Provider>
  );
};

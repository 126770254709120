import React, { useCallback, useEffect, useMemo } from "react";
import { STATUS_OPTIONS } from "../../../../../../components/status-popup-component";
import Style from "./InventorySingleCreateTab.module.css";
import { useAddInventoryFeatureV2StateContext } from "../../contexts/add-inventory-feature-v2-state-context";
import SingleCreateForm from "../../../../../../components/single-create-form";
import useOnboardingForm from "../../../../../../hooks/use-onboarding-form";

export const InventorySingleCreateTab = () => {
  const {
    formDataMap,
    setFormDataMap,
    submitStatus,
    setSubmitStatus,
    fetchedFormSections,
    formFields
  } = useAddInventoryFeatureV2StateContext();

  const { onFormSectionsChange, updateFormSectionsWithFormData } = useOnboardingForm();

  const processedFormSections = useMemo(() => {
    const { data = {} } = Object.values(formDataMap)[0] || {};

    return updateFormSectionsWithFormData(data, fetchedFormSections);
  }, [fetchedFormSections, formDataMap]);

  const onFormChange = useCallback(
    (fieldId, value) => {
      const { data = {} } = Object.values(formDataMap)[0] || {};

      const newFormData = onFormSectionsChange(fieldId, value, fetchedFormSections, formFields, data);
      setFormDataMap({ id: { data: newFormData } });
    },
    [fetchedFormSections, formDataMap]
  );

  const resetFormData = () => {
    if (formFields) {
      const newFormData = {};
      Object.keys(formFields).forEach((fieldName) => {
        newFormData[fieldName] = formFields[fieldName].defaultValue;
      });
      if (Object.keys(newFormData).length) {
        setFormDataMap({ id: { data: newFormData } });
      }
    }
  };

  useEffect(() => {
    const { data = {} } = Object.values(formDataMap)[0] || {};

    // Clears popup message
    if (submitStatus === STATUS_OPTIONS.ERROR) {
      setSubmitStatus(STATUS_OPTIONS.NONE);
    } else if (submitStatus === STATUS_OPTIONS.SUCCESS && data?.item_number) {
      setSubmitStatus(STATUS_OPTIONS.NONE);
    }
  }, [formDataMap]);

  useEffect(() => {
    resetFormData();
  }, [formFields]);

  useEffect(() => {
    if (submitStatus === STATUS_OPTIONS.SUCCESS) {
      resetFormData();
    }
  }, [submitStatus]);

  return (
    <div className={submitStatus === STATUS_OPTIONS.LOADING ? Style.modal_disabled : ""}>
      <SingleCreateForm
        title="Item Information"
        formSections={processedFormSections}
        onFormChange={onFormChange}
      />
    </div>
  );
};

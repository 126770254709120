import React, { useState, memo, useRef, useEffect } from "react";
import { useStore } from "react-hookstore";
import { MDBInput } from "mdbreact";
import PropTypes from "prop-types";
import { loggingUserStore } from "../../../state-managements/stores/login-store";
import { isValidEmail } from "../../../common/Utilities";
import "../LoginPage.css";
import { ReactComponent as CircleArrowRightIcon } from "../../../assets/icons/circle-arrow-right.svg";
import { isValidFederatedSignInEmail } from "../../../utils/is-valid-federated-sign-in-email/isValidFederatedSignInEmail";
import { getFederatedSignInCustomProvider } from "../../../utils/get-federated-sign-in-custom-provider/getFederatedSignInCustomProvider";

const LoginForm = memo(({ logo, fnSubmit, fnForgotPassword }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setLoggingUser] = useStore(loggingUserStore);

  const [emailOnlyMode, setEmailOnlyMode] = useState(true);

  const passwordInputRef = useRef(null);

  const onUsernameInput = (event) => {
    const user = event.target.value;
    setUsername(user);
  };

  const onPasswordInput = (event) => {
    const pwd = event.target.value;
    setPassword(pwd);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const trimmedUserName = username.trim();

    if (trimmedUserName.includes(" ")) {
      setErrorMessage("Username cannot contain any space");
    } else {
      setLoggingUser(trimmedUserName);
      try {
        await fnSubmit(
          trimmedUserName,
          password,
          isValidFederatedSignInEmail(trimmedUserName),
          getFederatedSignInCustomProvider(trimmedUserName)
        );
      } catch (error) {
        if (error.code === "InvalidParameterException") {
          setErrorMessage("Please enter your password");
        } else {
          setErrorMessage(error.message);
        }
      }
    }
  };

  const onForgotPassword = () => {
    setLoggingUser(username);
    fnForgotPassword(username);
  };

  const onNextPress = async (event) => {
    event.preventDefault();
    const trimmedUserName = username.trim();

    if (trimmedUserName && isValidEmail(trimmedUserName)) {
      if (isValidFederatedSignInEmail(trimmedUserName)) {
        await fnSubmit(trimmedUserName, null, true, getFederatedSignInCustomProvider(trimmedUserName));
      } else {
        setEmailOnlyMode(false);
      }
    }
  };

  useEffect(() => {
    if (passwordInputRef.current && !emailOnlyMode) {
      passwordInputRef.current.setFocus(true);
    }
  }, [passwordInputRef.current, emailOnlyMode]);

  return (
    <div className="content-body login-page-body">
      <div
        className="container login-form"
        style={{ textAlign: "initial" }}
      >
        <form className="login-form-background">
          <img
            src={logo}
            className="brand-logo"
            alt="brand-logo"
          />
          <div className="error_message">{errorMessage}</div>
          <div className="form-group-email-only">
            <MDBInput
              className="input-default"
              label="Email"
              id="username"
              onInput={onUsernameInput}
              data-cy-login-form__email-input
            />
            {emailOnlyMode && (
              <button
                type="submit"
                onClick={onNextPress}
                data-cy-login-form__email-next-button
              >
                <CircleArrowRightIcon className="next_button" />
              </button>
            )}
          </div>
          {!emailOnlyMode && (
            <>
              <div className="form-group">
                <MDBInput
                  ref={passwordInputRef}
                  className="input-default"
                  label="Password"
                  id="password"
                  onInput={onPasswordInput}
                  type="password"
                  data-cy-login-form__password-input
                />
              </div>
              <button
                type="submit"
                onClick={onSubmit}
                className="add-user-button"
                data-cy-login-form__login-button
              >
                Log In
              </button>

              <div className="center-align">
                <button
                  onClick={onForgotPassword}
                  className="asText"
                  type="button"
                >
                  Forgot Password?
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
});

LoginForm.propTypes = {
  logo: PropTypes.string.isRequired,
  fnSubmit: PropTypes.func.isRequired,
  fnForgotPassword: PropTypes.func.isRequired
};

export { LoginForm };

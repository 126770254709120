import React, { useState, useContext } from "react";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component";

const CheckOutTableStateContext = React.createContext();

export const useCheckOutTableStateContext = () => {
  return useContext(CheckOutTableStateContext);
};

export const CheckOutTableStateContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(STATUS_OPTIONS.NONE);
  const [statusMessage, setStatusMessage] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [locationOptionsMap, setLocationOptionsMap] = useState({});
  const [readerLocationOptionsMap, setReaderLocationOptionsMap] = useState({});
  const [itemByTag, setItemByTag] = useState({});
  const [itemTypeReports, setItemTypeReports] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [panelValues, setPanelValues] = useState({});
  const [optionTemplateMap, setOptionTemplateMap] = useState({});

  return (
    <CheckOutTableStateContext.Provider
      value={{
        loading,
        setLoading,
        selectedAction,
        setSelectedAction,
        locationOptionsMap,
        setLocationOptionsMap,
        readerLocationOptionsMap,
        setReaderLocationOptionsMap,
        itemByTag,
        setItemByTag,
        itemTypeReports,
        setItemTypeReports,
        filterInput,
        setFilterInput,
        panelValues,
        setPanelValues,
        isSubmitting,
        setIsSubmitting,
        submitStatus,
        setSubmitStatus,
        statusMessage,
        setStatusMessage,
        optionTemplateMap,
        setOptionTemplateMap
      }}
    >
      {children}
    </CheckOutTableStateContext.Provider>
  );
};

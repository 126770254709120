import React, { useState } from "react";
import { MDBInput } from "mdbreact";
import PropTypes from "prop-types";
import { useStore } from "react-hookstore";
import { loggingUserStore } from "../../../state-managements/stores/login-store";

const ForgotPasswordForm = ({ logo, onCancel, onSubmit }) => {
  const [loggingUser, setLoggingUser] = useStore(loggingUserStore);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState(loggingUser || "");
  const [isValidForm, setIsFormValid] = useState(username.length > 0);

  const onInputChangeInternal = (event) => {
    const user = event.target.value;
    setUsername(user);
    setIsFormValid(user.length > 0);
  };

  return (
    <div className="content-body login-page-body">
      <div
        className="container login-form"
        style={{ textAlign: "initial" }}
      >
        <form className="login-form-background">
          <img
            src={logo}
            className="brand-logo"
            alt="brand-logo"
          />

          <div className="form-group">
            <p
              style={{
                textAlign: "center",
                color: "white",
                marginBottom: 20
              }}
            >
              Please enter your Email. A temporary password will be sent to your registered email address.
            </p>
            <div className="error_message">{errorMessage}</div>
            <MDBInput
              className="input-default"
              label="Email"
              id="forgotPasswordUsername"
              value={username}
              onInput={onInputChangeInternal}
            />
          </div>
          <div className="profile-form-buttons">
            <button
              className="cancel-button change-password-button"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!isValidForm}
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                setLoggingUser(username.trim().toLowerCase());
                try {
                  await onSubmit(username.trim().toLowerCase());
                } catch (error) {
                  setErrorMessage(error.message);
                }
              }}
              className="default-button  change-password-button"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  logo: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export { ForgotPasswordForm };
